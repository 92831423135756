import React, { useEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { getters } from 'redux/store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { PATH_PRODUCT } from 'utils/constants/paths';
import { useAPI } from 'utils/api';

import imageCross from 'images/icons/i-cross-gray.svg';
import imagePlus from 'images/icons/i-plus-green.svg';
import imageMinus from 'images/icons/i-minus-green.svg';

import { setAppsFlyerRemoveFromCart } from 'utils/_appsflyer';
import { setInsiderItemRemovedFromCart } from 'utils/_insider';

const Container = styled.div`
  padding: 16px 2px 16px 0;

  &:not(:nth-last-child(2)) {
    border-bottom: 1px solid #e4e4e4;
  }
`;

const QtyDisable = styled.div`
  pointer-events: none;
  opacity: 0.4;
`;

const ShowDetailBlock = styled.div`
  padding: 3px 2px 5px 70px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ShowTitle = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 6px;
  gap: 10px;
  justify-content: space-between;
  height: 18px;
  left: 75px;
  top: 76px;
  background: #fdf8e4;
  border: 1px solid #f2ecd2;
  border-radius: 20px;
  max-width: calc(100% - 51px);
`;

const ShowContent = styled.span`
  height: 14px;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const DisabledContent = styled.span`
  left: 88%;
  right: 0.57%;
  top: 8.57%;
  bottom: 88.25%;

  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: right;
  text-decoration-line: line-through;

  color: #999999;
`;

const ContentBlock = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ProductImage = styled.div`
  flex: 0 0 70px;
  height: 70px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const TitleBlock = styled.div`
  padding-left: 5px;
  flex: 0 0 calc(100% - 100px);
`;

const ProductTitle = styled.div`
  height: 46px;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ProductSubTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #aaaaaa;
`;

const IconCross = styled.div`
  margin: 4px 0 0 auto;
  flex: 1;
  display: flex;
  justify-content: flex-end;

  img {
    width: 14px;
    height: 14px;
  }
`;

const QtyPriceBlock = styled.div`
  padding: 3px 2px 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const QtyCounter = styled.div`
  width: 97px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 0 8px;

  span {
    font-size: 18px;
  }
`;

const CartItem = ({
  itemData,
  checkBag,
  setCheckBag,
  remove,
  update,
  checkQtyFinish,
  isNeedCheckQty,
  saleOutHandle,
  type = 'normal',
}) => {
  // 發送update cart api的延遲時間
  const UpdateCartItemDelayTime = 1 * 1000;
  const api = useAPI();
  const isSoldOut = useRef(false);
  const currentStoreId = useSelector((state) => getters.getStoreId(state));
  const [qtyDisable, setQtyDisable] = useState(true);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState();
  const [showItem, setShowItem] = useState(true);
  const timeoutId = useRef(null);

  const lastConfirmQty = useRef(0);

  const deleteItem = () => {
    // 先把商品隱藏
    setShowItem(false);
    api
      .removeCart({
        productId: itemData.productId,
        currentStoreId: currentStoreId,
        additionalItem: type === 'normal' ? 0 : 1,
      })
      .then((res) => {
        // insider
        setInsiderItemRemovedFromCart(itemData.productId);
        remove(res);
      })
      .catch((error) => {
        // 有問題再開回來
        setShowItem(true);
        console.log(error);
      });
  };

  const updateItem = (mode) => {
    var count = 0;
    count = mode === 'increase' ? qty + 1 : qty - 1;

    if (count > itemData.c_maxOrderQuantity) {
      alert('已達商品購買上限');
      return;
    }

    const currentQty = qty;

    updateCartAPI(count, currentQty);
  };

  const updateCartAPI = (count, orgQty = 0, mode = 'increase') => {
    api
      .updateCart({
        productId: itemData.productId,
        quantity: count,
        currentStoreId: currentStoreId,
      })
      .then((res) => {
        if (!res) {
          return;
        }

        // appsflyer
        if (mode !== 'increase') setAppsFlyerRemoveFromCart(itemData);

        update(res);
        const countPrice = itemData.basePrice * count;
        setQty(count);
        setPrice(countPrice);
      });
  };

  const increaseQty = () => {
    if (!checkBag) {
      setCheckBag(true);
    }
    updateItem('increase');
  };

  const decreaseQty = () => {
    if (!checkBag) {
      setCheckBag(true);
    }
    const countQty = qty - 1;
    if (countQty === 0) {
      deleteItem();
      return;
    }
    updateItem('decrease');
  };

  const getCartCheckQty = () => {
    const parameters = {
      productlist: [
        {
          productId: itemData.productId,
          qty: itemData.qty,
        },
      ],
    };
    api
      .getCartCheckQty(parameters)
      .then((res) => {
        if (!res) return;
        res.productItems.forEach((element) => {
          if (element.productId === itemData.productId && !element.isbonus) {
            isSoldOut.current = !element.remain;
          }
        });
        setQtyDisable(isSoldOut.current);
        if (isSoldOut.current) {
          saleOutHandle(itemData.itemName);
        }
        checkQtyFinish();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickDelete = (e) => {
    e.stopPropagation();
    deleteItem();
  };

  const handleClickProduct = () => {
    navigate(`${PATH_PRODUCT}/${itemData.productId}`);
  };

  useEffect(() => {
    setQty(itemData.qty);
    lastConfirmQty.current = itemData.qty;
    setPrice(itemData.price);

    if (isNeedCheckQty) {
      getCartCheckQty();
    } else {
      setQtyDisable(false);
    }
  }, []);

  return showItem ? (
    <Container>
      {qtyDisable ? (
        <QtyDisable>
          <ContentBlock onClick={handleClickProduct}>
            <ProductImage>
              <img src={itemData.image} />
            </ProductImage>

            <TitleBlock>
              <ProductTitle>{itemData.itemName}</ProductTitle>
              <ProductSubTitle>{itemData.itemSpec}</ProductSubTitle>
            </TitleBlock>

            <IconCross onClick={handleClickDelete}>
              <img src={imageCross} />
            </IconCross>
          </ContentBlock>

          {itemData.productPromotions?.length > 0 && (
            <div>
              {itemData.productPromotions.map((item, index) => (
                <div key={index}>
                  {item.calloutMsg !== '' && (
                    <ShowDetailBlock>
                      <ShowTitle>
                        <ShowContent>{item.calloutMsg}</ShowContent>
                      </ShowTitle>
                      {price !== itemData.nonAdjustedPrice && index === 0 && (
                        <DisabledContent>{`$${itemData.nonAdjustedPrice}`}</DisabledContent>
                      )}
                    </ShowDetailBlock>
                  )}
                </div>
              ))}
            </div>
          )}

          <QtyPriceBlock>
            <QtyCounter
              style={{
                visibility: `${
                  type !== 'addition' && type !== 'gift' ? 'visible' : 'hidden'
                }`,
              }}
            >
              <img src={imageMinus} onClick={() => decreaseQty()} />
              <span>{qty}</span>
              <img src={imagePlus} onClick={() => increaseQty()} />
            </QtyCounter>
            <span>{`$${price}`}</span>
          </QtyPriceBlock>
        </QtyDisable>
      ) : (
        <>
          <ContentBlock onClick={handleClickProduct}>
            <ProductImage>
              <img src={itemData.image} />
            </ProductImage>

            <TitleBlock>
              <ProductTitle>{itemData.itemName}</ProductTitle>
              <ProductSubTitle>{itemData.itemSpec}</ProductSubTitle>
            </TitleBlock>

            {type !== 'gift' && (
              <IconCross onClick={handleClickDelete}>
                <img src={imageCross} />
              </IconCross>
            )}
          </ContentBlock>

          {itemData.productPromotions?.length > 0 && (
            <div>
              {itemData.productPromotions.map((item, index) => (
                <div>
                  {item.calloutMsg !== '' && (
                    <ShowDetailBlock>
                      <ShowTitle>
                        <ShowContent>{item.calloutMsg}</ShowContent>
                      </ShowTitle>
                      {price !== itemData.nonAdjustedPrice && index === 0 && (
                        <DisabledContent>{`$${itemData.nonAdjustedPrice}`}</DisabledContent>
                      )}
                    </ShowDetailBlock>
                  )}
                </div>
              ))}
            </div>
          )}

          <QtyPriceBlock>
            <QtyCounter
              style={{
                visibility: `${
                  type !== 'addition' && type !== 'gift' ? 'visible' : 'hidden'
                }`,
              }}
            >
              <img src={imageMinus} onClick={() => decreaseQty()} />
              <span>{qty}</span>
              <img src={imagePlus} onClick={() => increaseQty()} />
            </QtyCounter>
            {type !== 'gift' && <span>{`$${price}`}</span>}
          </QtyPriceBlock>
        </>
      )}
    </Container>
  ) : (
    <div> </div>
  );
};

export default CartItem;
