import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { useInput } from 'rooks';
import { navigate } from 'gatsby';
import { decode } from 'js-base64';
import styled from 'styled-components';

import { EmailCombineDialog } from 'components/dialog';
import HeaderBar from 'components/header-bar/index.jsx';
import DeliveryBar from 'components/delivery-bar/index.jsx';
import {
  MemberValidationDialog,
  WalletDialogV2,
  SaleOutAlert,
} from 'components/dialog';
import CartItem from 'components/cart/cart-item';
import RecycleBagItem from 'components/cart/recycle-bagitem';
import DiscountCard from 'components/cart/discount-card';
import ModalCoupon from './ModalCoupon';
import SwiperProduct from 'components/Swiper/SwiperProduct';
import AdditionBlock from 'components/addition-block';
import RecommendProductBlock from 'components/recommend-block';

import { API_RESPONSE } from 'api/_const';
import { useAPI } from 'utils/api';
import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { showLoading, dismissLoading } from 'utils/loading';
import {
  setViewCart,
  setBeginCheckout,
  setAddPaymentInfo,
  setAddShippingInfo,
} from 'utils/_ga';
import { setMetaViewCart, setMetaBeginCheckout } from 'utils/_meta';
import { pageCheck } from 'utils/pageCheck';
import { INVOICE_TYPE } from 'utils/constants/invoiceType.js';
import { setOrderFlowFailed, setOrderOvertime } from '../../utils/_RateData';
import {
  setAppsFlyerInitiateCheckout,
  setAppsFlyerViewCart,
} from 'utils/_appsflyer';

import Cross from 'images/icons/i-cross-black.svg';
import imageLocationPoint from 'images/icons/i-location-point.svg';
import imageAngleArrorRight from 'images/icons/angle-arrow-right-black-s.svg';
import IconWallet from 'images/icons/c4wallet-2.svg';
import imageMastercard from 'images/icons/i-mastercard.png';
import IconLinepay from 'images/icons/i-linepay.svg';
import IconNotice from 'images/icons/i-notice.svg';
import IconJko from 'images/icons/jkopay.png';
import IconApplePay from 'images/icons/Apple-Pay-Mark.png';
import { initTPDirect, tapPayRedirect, getDeviceId } from 'utils/tappay';
import { setAdditionItems } from '../../redux/slices/_additionItems';
import { checkTaxNumberV2 } from 'utils/_invoiceCheck';
// 使用cookie
import { saveCookie, fetchCookie } from 'utils/cookie';
import {
  setInsiderCustomEvent,
  setInsiderCartPageView,
  setInsiderCartClear,
} from 'utils/_insider';

const IN_APP = process.env.GATSBY_INAPP === 'true';

const RecommendBlock = styled.div`
  width: auto;
  background-color: #eafeff;
  font-size: 14px;
  font-weight: 400;
  color: #5fd2da;
  padding: 12px 0;
  text-align: center;
  margin: 10px -2px 0;
`;

const AddressBar = styled.div`
  padding: 0 15px;
  background: linear-gradient(0deg, #ffffff 50%, #f2f2f3 50%);

  .address-block {
    height: 51px;
    background: #ffffff;
    border: 1px solid #5fd2da;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    div {
      display: flex;
      align-items: center;

      img {
        margin-left: 12px;
      }

      span {
        margin-left: 5px;
        width: 70vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    > img {
      margin-right: 20px;
    }
  }
`;

const CartContainer = styled.div`
  padding: 0 16px;

  .coupon-notification {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #3b3516;
    background: #fdf8e4;
    border: 1px solid #f2ecd2;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    margin: 16px 0;
  }

  .total-amount {
    padding: 7px 0;
    // border-bottom: 1px solid #e4e4e4;
    div {
      height: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
    }
    // div:nth-child(3) {
    //   span:nth-child(2) {
    //     color: #5fd2da;
    //   }
    // }
    // div:nth-child(4) {
    //   font-weight: bold;
    //   font-size: 18px;
    //   text-align: right;
    //   color: #333333;
    // }

    div:nth-last-child(1) {
      font-weight: bold;
      font-size: 18px;
      text-align: right;
      color: #333333;
    }

    .discount-amount {
      display: flex;
      align-items: center;
    }
    .discount {
      color: #5fd2da;
    }
  }

  .addition {
    padding: 16px;
    // border-top: 1px solid #e4e4e4;
    // border-bottom: 1px solid #e4e4e4;
    margin: 0 -16px;
    background: rgba(215, 244, 246, 0.6);

    .use-addition {
      font-family: Noto Sans TC;
      font-style: normal;
      font-weight: bold;
      line-height: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #333333;
    }
  }

  .coupon {
    padding: 16px;
    // border-top: 1px solid #e4e4e4;
    // border-bottom: 1px solid #e4e4e4;
    margin: 0 -16px;
    background: rgba(215, 244, 246, 0.6);

    &.has-coupon {
      background: rgba(215, 244, 246, 0.6);
      border: none;

      .use-coupon {
        margin-bottom: 10px;
      }
    }

    .use-coupon {
      font-family: Noto Sans TC;
      font-style: normal;
      font-weight: bold;
      line-height: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #333333;
    }

    .coupon-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .delete-coupon {
        white-space: nowrap;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        padding: 2px 8px;
        color: #ffffff;
        background: #5fd2da;
        border-radius: 20px;
        height: 20px;
        width: 50px;
        text-align: center;
        margin-right: 20px;
      }
      .coupon-desc {
        line-break: anywhere;
      }
    }
  }

  .payment {
    padding: 16px 0;
    margin: 0 -16px;
    background: #d7f4f6;
    // border-bottom: 1px solid #e4e4e4;
    .title {
      margin: 0 16px;
      height: 23px;
      font-weight: 700;
    }
    .point-discount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      // border: 1px solid #e4e4e4;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px;
      margin-top: 16px;
      margin-bottom: 18px;

      span {
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        color: #5fd2da;
      }
    }
    .credit-block {
      margin: 0 16px;
      height: 23px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .credit-card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        span {
          margin-left: 8px;
        }
      }
    }
  }
  .invoice {
    padding: 16px 0 26px 0;
    border-bottom: 1px solid #e4e4e4;

    .title {
      height: 23px;
      font-size: 16px;
      line-height: 23px;
      color: #333333;
      margin-bottom: 10px;
      font-weight: 700;
    }
    input[type='radio'] {
      display: none;
    }
    input[type='radio'] + label {
      color: #333333;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div {
        font-size: 18px;
        line-height: 26px;
        color: #333333;
        margin-left: 7px;
      }
    }

    input[type='radio'] + label span {
      width: 14px;
      height: 14px;
      background-color: #d2d2d2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      span {
        width: 10px;
        height: 10px;
        background-color: #ffffff;
        order-radius: 50%;
      }
    }

    input[type='radio']:checked + label span {
      width: 14px;
      height: 14px;
      background-color: #5fd2da;
      display: flex;
      justify-content: center;
      align-items: center;
      order-radius: 50%;

      span {
        width: 4px;
        height: 4px;
        background-color: #ffffff;
        order-radius: 50%;
      }
    }
    .carrier-block {
      padding-bottom: 19px;
      .inputdata {
        display: table-caption;
      }
      .notice {
        color: red;
      }
    }
    .barcode {
      width: 180px;
      height: 40px;
      background-color: #fffefc;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      outline: none;
      margin: 5px 0 20px 17px;
    }
    .certificate {
      margin-top: 23px;
      height: 20px;
      background-color: #f7f7f7;
      border: none;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      &::placeholder {
        font-size: 16px;
        color: #333333;
      }
    }
    .company {
      margin-top: 23px;
      height: 20px;
      background-color: #f7f7f7;
      border: none;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      &::placeholder {
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .provision,
  .order-remark {
    min-height: auto;
    word-break: break-all;
    padding: 16px 0;
    font-size: 16px;
    line-height: 23px;
    color: #333333;
    border-bottom: 1px solid rgb(228, 228, 228);

    .title {
      margin-bottom: 8px;
    }

    .content {
      box-sizing: border-box;
      min-height: 60px;
      width: 100%;
      padding: 8px;
    }
  }
`;

const DeliveryNotice = styled.div`
  padding: 20px;

  .delivery-remark {
    width: 100%;
    min-height: auto;
    padding-bottom: 15px;
    box-sizing: border-box;
    textarea {
      margin-top: 15px;
      box-sizing: border-box;
      min-height: 60px;
      width: 100%;
    }
  }
  .elevator,
  .sign {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      line-height: 30px;
    }
    .title {
      font-size: 18px;
    }
    .title,
    .radio1,
    .radio2 {
      margin-right: 14px;
    }
  }
  .sign {
    flex-flow: wrap;
    margin-top: 12px;
    div {
      flex-shrink: 0;
      flex-grow: 0;
      margin-bottom: 10px;
      div {
        margin-bottom: 0;
      }
    }
  }

  .notice {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 10px;
  }

  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label {
    color: #333333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin-left: 10px;
    }
  }
  input[type='radio'] + label span {
    width: 16px;
    height: 16px;
    background-color: #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    span {
      width: 14px;
      height: 14px;
      background-color: #ffffff;
      order-radius: 50%;
    }
  }
  input[type='radio']:checked + label span {
    width: 16px;
    height: 16px;
    background-color: #5fd2da;
    display: flex;
    justify-content: center;
    align-items: center;
    order-radius: 50%;

    span {
      width: 4px;
      height: 4px;
      background-color: #ffffff;
      order-radius: 50%;
    }
  }
`;

const BottomButton = styled.div`
  &.disabled {
    pointer-events: none;
  }

  width: calc(100vw - 24px);
  height: 60px;
  padding: 20px 12px;
  background-color: #ffffff;
  box-shadow: 0px 10px 15px 10px #e0e0e0;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .button-block {
    width: 100%;
    height: 60px;
    padding: 0 35px;
    background: #5fd2da;
    border: 1px solid #5fd2da;
    box-sizing: border-box;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 29px;
    color: #ffffff;
    .confirm-block {
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div {
        width: 24px;
        height: 24px;
        background-color: #f9e05f;
        font-size: 20px;
        line-height: 29px;
        text-align: right;
        color: #3b3516;
        border-radius: 50%;
        margin-left: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const MobileItemListTitle = styled.div`
  padding-top: 10px;
`;

const OrderDiscountsBlock = styled.div`
  background-color: #fdf8e4;
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid #e4e4e4;

  > span {
    font-weight: 700;
    display: inline-block;
    margin: 0 3px;
    color: red;
  }
`;

var saleOutList = [];
let isUserDeleteItem = false;
const Cart = (props) => {
  // 發送make order api的延遲時間
  const MakeOrderDelayTime = 2 * 1000;
  const PaymentMethodC4Wallet = 20;
  const PaymentMethodC4Point = 24; //全額折抵
  const PaymentMethodJkoPay = 19;
  const PaymentMethodCreditCard = 1;
  const PaymentMethodApplePay = 12;
  const PaymentMethodLinePay = 14;

  const { location } = props;
  const api = useAPI();

  let pageName =
    location.state === null || location.state.page === ''
      ? '購物車頁'
      : location.state.page;

  const payment = useSelector((state) => getters.getPayment(state));
  const creditInfo = useSelector((state) => getters.getCreditInfo(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const hashAccount = useSelector((state) => getters.getHashAccount(state));
  const accessToken = useSelector((state) => getters.getAccessToken(state));
  const cartAdditionItems = useSelector((state) =>
    getters.getCartAdditionItems(state)
  );
  const cartGiftItems = useSelector((state) => getters.getCartGiftItems(state));
  const successOrderCount = useSelector((state) =>
    getters.getSuccessOrderCount(state)
  );

  // getters中取得attribution token
  const attributionToken = useSelector((state) =>
    getters.getAttributionToken(state)
  );

  const [showModalCoupon, setShowModalCoupon] = useState(false);

  const [isCheckQtyFinished, setIsCheckQtyFinished] = useState(false);

  const [showCartAdditionItems, setShowCartAdditionItems] = useState(false);
  const [showCartGiftItems, setShowCartGiftItems] = useState(false);
  const [showCartItems, setShowCartItems] = useState(false);
  const [isNeedCheckQty, setIsNeedCheckQty] = useState(true);
  const [cartData, setCartData] = useState({});
  const [totalQty, setTotalQty] = useState(0);
  const [recycleBag, setRecycleBag] = useState({});
  const [checkBag, setCheckBag] = useState(true);
  const [memberProfile, setMemberProfile] = useState({});
  const [productTotal, setProductTotal] = useState(0);
  const [shippingTotal, setShippingTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showSaleOut, setShowSaleOut] = useState(false);
  // const [saleOutContent, setSaleOutContent] = useState('');
  const [hasCoupon, setHasCoupon] = useState(false);
  const [shippingModel, setShippingModel] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [receiveTypeValue, setReceiveTypeValue] = useState('');
  const [elevatorValue, setElevatorValue] = useState('');
  const [selectTimeslotIndex, setSelectTimeslotIndex] = useState(0);
  const [cartListUpdate, setCartListUpdate] = useState(false);
  const [showWalletDialog, setShowWalletDialog] = useState(false);
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [showWalletText, setShowWalletText] = useState('');
  const [isDiscountCardShow, setIsDiscountCardShow] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  // const [saleOutList, setSaleOutList] = useState([]);
  const [walletValue, setWalletValue] = useState();
  const walletPassword = useRef('');
  const [selectTimeslot, setSelectTimeslot] = useState({
    shippingDate: '',
    shippingPeriod: '',
    shippingTimeslot: '',
    fastDeliveryPeriod: '',
  });
  const [receiverAddress, setReceiverAddress] = useState('');
  const [adjustedAddress, setAdjustedAddress] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [floor, setFloor] = useState('');
  const [updateAddition, setUpdateAddition] = useState(false);
  const [noteDispatch, setNoteDispatch] = useState('');
  const [noteOrder, setNoteOrder] = useState('');
  const [mobileCode, setMobileCode] = useState('');
  const [natural, setNatural] = useState('');
  const [companyNo, setCompanyNo] = useState('');
  const discountCodeInput = useInput('');
  const postData = useRef({});
  const productFeedData = useRef({});
  const [walletNotOpen, setWalletNotOpen] = useState(false);
  const clpeValid = useRef(false);
  const walletValid = useRef(false);
  const orderParams = useRef({});
  const [returnContract, setReturnContract] = useState(true);
  const [agreePolicy, setAgreePolicy] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const [freeShippingAmount, setFreeShippingAmount] = useState(0);
  const [shippingFinal, setShippingFinal] = useState(0);
  const [tbGoogleSwitch, setTbGoogleSwitch] = useState('');
  const appDispatchData = useRef({});
  const makeOrderVersion = useRef();
  const [invoiceValue, setInvoiceValue] = useState('0');
  const [showOrderTotal, setShowOrderTotal] = useState(0);
  const [orderDiscountInfo, setOrderDiscountInfo] = useState([]);

  // 使用useState存取cartList
  const [cartList, setCartList] = useState([]);

  const checkQtyCount = useRef(0);
  // 錢包支付(20)或紅利全額折抵(24)
  const isInvoiceTypeDisabled =
    payment.payment === 20 || (payment.payment === 24 && showOrderTotal === 0);
  const calcShowOrderTotal = (oTotal, clpeValue) => {
    return setShowOrderTotal(oTotal - clpeValue);
  };
  const storeTimeSlots = useSelector((state) =>
    getters.getStoreTimeSlots(state)
  );

  const closeModalCoupon = () => {
    setShowModalCoupon(false);
  };

  const makeOrderProcess = useRef(false);
  const [updateRecommendBlock, setUpdateRecommendBlock] = useState(false);

  const handleClickCoupon = () => {
    setShowModalCoupon(true);
  };

  const deleteItem = (res, recycleBag) => {
    setUpdateAddition(true);
    setUpdateRecommendBlock(true);
    updateOrderDiscountInfo(res.approachingOrderDiscounts);
    isUserDeleteItem = true;
    // setCartList(res.productItems);

    //將商品區分為加價購與一般商品
    const arrAdditionItems = res.productItems.filter(
      (element) => element.isAdditionItem
    );
    const arrNormalItems = res.productItems.filter(
      (element) => !element.isAdditionItem && !element.isGiftItem
    );
    const arrGiftItems = res.productItems.filter(
      (element) => element.isGiftItem
    );
    setCartList(arrNormalItems);
    store.dispatch(actions.setCartAdditionItems(arrAdditionItems));
    store.dispatch(actions.setCartGiftItems(arrGiftItems));
    setShowCartAdditionItems(true);
    setShowCartGiftItems(true);

    if (res.productItems.length <= 0) {
      if (isUserDeleteItem) {
        isUserDeleteItem = false;
        setInsiderCartClear();
        alert('目前購物車沒有商品。', () => {
          callAppFunc('backMain', {});
        });
      }
      return;
    }

    setProductTotal(res.productTotal);
    setShippingTotal(res.shippingTotal);
    setDiscount(res.discount);
    setOrderTotal(res.orderTotal);
    setTotalQty(res.totalqtyCount);
    setShippingFinal(res.shippingFinal);

    const hasCp = res.discountList.find((item) => {
      return item.type === 'coupon';
    });

    setHasCoupon(hasCp !== undefined && hasCoupon !== null);

    if (res.discountList.length > 0) {
      // setDiscountCode(discountCodeInput.value);
      // setHasCoupon(
      //   discountCodeInput.value !== undefined &&
      //     discountCodeInput.value !== ''
      // );
      setDiscountList(res.discountList);
    }

    setFreeShippingAmount(
      IN_APP
        ? postData.current.shippingModel === '1'
          ? res.shippingMethod.freeShippingAmount
          : res.shippingMethod.pickOnStoreAmount
        : res.shippingMethod.freeShippingAmount
    );

    checkBonus(
      res.orderTotal,
      res.productTotal,
      res.discount,
      res.shippingTotal
    );
    if (recycleBag) {
      window.popup('已將購物袋刪除');
    } else {
      window.popup('已刪除商品');
    }
  };

  const updateItem = (res) => {
    setProductTotal(res.productTotal);
    setShippingTotal(res.shippingTotal);
    setDiscount(res.discount);
    setOrderTotal(res.orderTotal);
    setTotalQty(res.totalqtyCount);
    setShippingFinal(res.shippingFinal);
    updateOrderDiscountInfo(res.approachingOrderDiscounts);

    // 將商品區分為加價購與一般商品;
    const arrAdditionItems = res.productItems.filter(
      (element) => element.isAdditionItem
    );
    const arrNormalItems = res.productItems.filter(
      (element) => !element.isAdditionItem && !element.isGiftItem
    );
    const arrGiftItems = res.productItems.filter(
      (element) => element.isGiftItem
    );
    setCartList(arrNormalItems);
    store.dispatch(actions.setCartAdditionItems(arrAdditionItems));
    store.dispatch(actions.setCartGiftItems(arrGiftItems));

    const hasCp = res.discountList.find((item) => {
      return item.type === 'coupon';
    });

    setHasCoupon(hasCp !== undefined && hasCoupon !== null);
    if (res.discountList.length > 0) {
      // setDiscountCode(discountCodeInput.value);
      // setHasCoupon(
      //   discountCodeInput.value !== undefined &&
      //     discountCodeInput.value !== ''
      // );
      setDiscountList(res.discountList);
    }

    setFreeShippingAmount(
      IN_APP
        ? postData.current.shippingModel === '1'
          ? res.shippingMethod.freeShippingAmount
          : res.shippingMethod.pickOnStoreAmount
        : res.shippingMethod.freeShippingAmount
    );

    checkBonus(
      res.orderTotal,
      res.productTotal,
      res.discount,
      res.shippingTotal
    );
  };

  const checkBonus = (total, tProductTotal, tDiscount, tShipping) => {
    var tmpPayment = payment;
    if (
      postData.current !== undefined &&
      postData.current !== null &&
      postData.current.bonusThreshold !== undefined &&
      postData.current.bonusThreshold !== null &&
      postData.current.bonusThreshold > total
    ) {
      tmpPayment = {
        ...tmpPayment,
        clpe: undefined,
      };
    }
    // 檢查點數是否正常
    if (
      tmpPayment &&
      tmpPayment.clpe &&
      tmpPayment.clpe.value &&
      tmpPayment.clpe.value >
        tProductTotal - (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0)
    ) {
      tmpPayment = {
        ...tmpPayment,
        clpe: undefined,
      };
    }

    // 檢查是否全額折抵
    if (
      tmpPayment &&
      tmpPayment.clpe &&
      tmpPayment.clpe.value &&
      tmpPayment.clpe.value ===
        tProductTotal -
          (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0) &&
      tProductTotal -
        (tDiscount - tShipping > 0 ? tDiscount - tShipping : 0) ===
        total
    ) {
      tmpPayment = {
        ...tmpPayment,
        payment: 24,
      };
    } else if (tmpPayment && tmpPayment.payment === 24) {
      tmpPayment = {
        ...tmpPayment,
        payment: undefined,
      };
    }

    calcShowOrderTotal(
      total,
      tmpPayment !== undefined && tmpPayment.clpe && tmpPayment.clpe.value
        ? tmpPayment.clpe.value
        : 0
    );
    setPaymentFunction(tmpPayment);
  };

  const goMakeOrder = () => {
    // if (isNeedCheckQty) {
    //   alert('庫存尚未確認完畢，請稍候操作。');
    //   return;
    // }
    if (isLockMakeOrderButton()) {
      return;
    }
    lockMakeOrderButton();

    const parameters = collectionData();
    const storeId = postData.current.storeId;

    clpeValid.current = false;
    walletValid.current = false;
    validPaymentCollectionData(parameters, (valid) => {
      if (!valid) {
        unlockMakeOrderButton();
        return;
      }
      checkStock(storeId, (isValid) => {
        if (!isValid) {
          unlockMakeOrderButton();
          return;
        }
        orderParams.current = parameters;
        checkPaymentMethod();
      });
    });
  };

  const lockMakeOrderButton = () => {
    makeOrderProcess.current = true;
  };

  const isLockMakeOrderButton = () => {
    return makeOrderProcess.current;
  };

  const unlockMakeOrderButton = () => {
    makeOrderProcess.current = false;
  };

  const checkPaymentMethod = () => {
    const isPoint = payment?.clpe?.point;
    const isWallet = payment.payment === PaymentMethodC4Wallet;
    const totalAfterDiscount =
      productTotal - Math.max(discount - shippingTotal, 0);

    if (
      payment?.clpe?.value &&
      payment.payment === PaymentMethodC4Point &&
      !(
        payment.clpe.value === totalAfterDiscount &&
        totalAfterDiscount === orderTotal
      )
    ) {
      alert('紅利金額錯誤，請檢查紅利金額。');
      unlockMakeOrderButton();
      return;
    }

    if (isPoint && !clpeValid.current && !isWallet) {
      sendValidCode((finished) => {
        if (!finished) {
          unlockMakeOrderButton();
          return;
        }
        setShowValidationDialog(true);
      });
      return;
    }

    if (!isWallet) {
      fetchOrder();
      return;
    }

    if (!userInfo.skey) {
      requestSkey();
      return;
    }

    proceedWithWallet();
  };

  const requestSkey = () => {
    showLoading();
    api
      .getSkey({ accessToken })
      .then((res) => {
        dismissLoading();
        if (res.code === '200') {
          store.dispatch(
            actions.setUserInfo({ ...userInfo, skey: res.data.skey })
          );
          setTimeout(proceedWithWallet, 200);
        } else {
          if (res.code === '405') {
            unlockMakeOrderButton();
          } else {
            alert(res.msg);
            unlockMakeOrderButton();
          }
        }
      })
      .catch((error) => {
        dismissLoading();
        unlockMakeOrderButton();
        alert(error);
      });
  };

  const proceedWithWallet = () => {
    getWallet();
    setShowWalletDialog(true);
  };

  const checkOS = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/ipad|iphone|ipod/i.test(userAgent)) {
      return 'iOS';
    }
    return 'unknown';
  };

  const makeOrderAfter = (res, paymentType, invoiceData = {}) => {
    // 已經進行完打單動作，設定一個1秒後解鎖按鈕
    setTimeout(() => {
      unlockMakeOrderButton();
    }, 5000);
    dismissLoading(true);
    if (res.code === API_RESPONSE.SUCCESS) {
      callAppFunc('saveSuccessedOrderCount', { count: successOrderCount + 1 });

      // 將attributionToken存入cookie
      saveCookie('attributionToken', attributionToken, 1);

      if (res.data.payment_url === '') {
        pageCheck(api, () => {
          navigate(
            `/order/detail/${res.data.sfcc_transaction_no}?from=payment`,
            { replace: false }
          );
        });
      } else {
        pageCheck(api, () => {
          if (paymentType === PaymentMethodJkoPay) {
            tapPayRedirect(res.data.payment_url);
          } else {
            navigate(res.data.payment_url, { replace: true });
          }
        });
      }

      if (Object.keys(invoiceData).length > 0) {
        localStorage.setItem('KeepInvoice', JSON.stringify(invoiceData));
      }

      if (paymentType !== 24) {
        localStorage.setItem(
          'KeepPaymentType',
          JSON.stringify({ payment: paymentType })
        );
      } else {
        localStorage.removeItem('KeepPaymentType');
      }
      setPaymentFunction({});
      // 刪除暫存卡號
      store.dispatch(actions.setNewCards([]));
      // 刪除暫存資料
      localStorage.removeItem('TmpCartData');
      sessionStorage.removeItem('TmpPaymentData');
      sessionStorage.removeItem('viewCart');
    } else if (res.code === '405') {
      handleOrderFlowFailed();
      alert(res.reason, () => {
        removeCoupons();
      });
      return;
    } else {
      handleOrderFlowFailed();
      const codeInt = Number.parseInt(res.code);
      if (codeInt === 503) {
        alert(res.reason, () => {
          callAppFunc('storeClose', {});
        });
        return;
      }
      if (codeInt >= 700 && codeInt < 800) {
        pageCheck(api, () => {
          navigate(
            `/message/payment-fail/${res.data.sfcc_transaction_no}?from=cart&reason=${res.reason}`,
            { replace: false }
          );
        });
        // 清除支付
        setTimeout(() => {
          setPaymentFunction({});
          // 刪除暫存卡號
          store.dispatch(actions.setNewCards([]));
          // localStorage.removeItem('TmpCartData');
          sessionStorage.removeItem('TmpPaymentData');
          sessionStorage.removeItem('viewCart');
        }, 1000);
      } else if (codeInt === 600) {
        alert(res.reason);
        getTimeSlots();
      } else if (codeInt === 428) {
        setShowEmail(true);
        // alert(res.reason, () => {
        //   setShowEmail(true);
        // });
      } else {
        alert(res.reason);
      }
    }
    //code==700 付款失敗 導到payment-fail  非700 ==400 badrequest alert(reason)
    //
    //code==200 && payment_url=="" 前往order/detail/[sfcc_transaction_no] 顯示訂單完成頁
    //code==200 && payment_url != "" 前往payment_url 3d驗證 交易完成後將轉導到payment/result?status="200 or 非200 "&trans="[sfcc_transaction_no]"
    //payment/result 這邊會根據status判斷 200視為成功前往order/detail/[sfcc_transaction_no] 顯示訂單完成頁  非200視為失敗前往message/payment-fail

    // navigate(res.payment_url, {
    //   replace: true,
    // });
  };

  const collectionData = () => {
    var cardNo = '';
    var expire = '';
    var cvc = '';
    var newCard = false;
    var isRemember = false;

    if (payment.payment === 1) {
      if (payment.cardInfo) {
        cardNo = payment.cardInfo.cardNo;
        expire = payment.cardInfo.expire;
        cvc = payment.cardInfo.cvc;
        newCard = payment.cardInfo.isNewCard;
        isRemember = payment.cardInfo.checked;
      } else {
        cardNo = creditInfo.creditCardNumber;
        expire = `${creditInfo.creditCardExpirationYear}${creditInfo.creditCardExpirationMonth}`;
      }
    }

    if (cardNo !== undefined && cardNo !== null && cardNo !== '') {
      cardNo = cardNo.split(' ').join('');
    }

    const os = checkOS();
    const productList = cartList
      .concat(cartAdditionItems)
      .concat(cartGiftItems);

    const objParam = productFeedData.current?.affiliateParams
      ? {
          ...productFeedData.current.affiliateParams,
          ...(productFeedData.current.affiliateParams.vtm_channel && {
            vtm_channel: productFeedData.current.affiliateParams.vtm_channel,
          }),
          ...(productFeedData.current.affiliateParams.sbid && {
            sbid: productFeedData.current.affiliateParams.sbid,
          }),
        }
      : {};

    const parameters = {
      storeId: postData.current.storeId,
      ecorderPlatform: os === 'iOS' ? 3 : os === 'Android' ? 4 : 1,
      paymentType: payment.payment,
      wallet_pwd: walletPassword.current,
      shippingDate:
        selectTimeslot === undefined || selectTimeslot.shippingDate === ''
          ? postData.current.shippingDate
          : selectTimeslot.shippingDate,
      shippingPeriod:
        selectTimeslot === undefined || selectTimeslot.shippingPeriod === ''
          ? postData.current.shippingPeriod
          : selectTimeslot.shippingPeriod, //postData.current.shippingPeriod,
      shippingTimeslot:
        selectTimeslot === undefined || selectTimeslot.shippingTimeslot === ''
          ? postData.current.shippingTimeslot
          : selectTimeslot.shippingTimeslot,
      fastDeliveryPeriod:
        selectTimeslot === undefined || selectTimeslot.fastDeliveryPeriod === ''
          ? postData.current.fastDeliveryPeriod
          : selectTimeslot.fastDeliveryPeriod, //selectTimeslot.fastDeliveryPeriod,
      shippingModel: postData.current.shippingModel,
      receiverName: postData.current.receiverName || memberProfile.name,
      receiverPhone: postData.current.receiverPhone || memberProfile.mobile,
      receiverAddress:
        tbGoogleSwitch === 'TB'
          ? `${adjustedAddress}${floor}`
          : postData.current.receiverAddress,
      receiverZip: postData.current.receiverZip,
      receiverElevator: elevatorValue, //postData.current.receiverElevator,
      receiverReceive: receiveTypeValue, //postData.current.receiverReceive,
      receiverAddressRemark: noteDispatch,
      remark: noteOrder,
      invoiceType: isInvoiceTypeDisabled ? null : invoiceValue,
      invoiceVehicle: isInvoiceTypeDisabled
        ? null
        : invoiceValue === '3'
        ? mobileCode
        : invoiceValue === '4'
        ? natural
        : '',
      uniformNumbers: invoiceValue === '1' ? companyNo : '',
      outofstockAction: '1',
      ecorderpayment: '1',
      creditCardNo: cardNo,
      expireYYMM: expire,
      cvsCode: cvc,
      isRemembered: newCard && isRemember ? true : !newCard ? true : false,
      products: productList.map((prod, index) => ({
        productid: prod.productId,
        itemName: prod.itemName,
        qty: prod.qty,
        itemOutofstockAction: prod.itemOutofstockAction,
      })),
      clpe_deduct:
        payment && payment.clpe && payment.clpe.point ? payment.clpe.point : 0,
      clpe_deduct_amount:
        payment && payment.clpe && payment.clpe.value ? payment.clpe.value : 0,
      new_card: newCard,
      // 新增加tpdirect get device id
      device_id: getDeviceId(),
      affiliateParams: objParam ?? {},
      // 增加addressId
      receiverAddressId: postData.current.addressId,
    };
    return parameters;
  };

  const fetchOrder = () => {
    const storeId = postData.current.storeId;
    const parameters = collectionData();

    validPaymentCollectionData(parameters, (valid) => {
      if (!valid) {
        unlockMakeOrderButton();
        return;
      }
      // checkStock(storeId, (valid) => {
      //   if (!valid) return;
      showLoading();
      let shippingFee = shippingFinal;
      let realDiscount =
        freeShippingAmount !== 0 ? discount : discount - shippingTotal;
      setBeginCheckout(
        hashAccount,
        cartList,
        discountList,
        showOrderTotal,
        realDiscount,
        pageName
      );
      setMetaBeginCheckout(cartList, showOrderTotal);

      // appsflyer
      setAppsFlyerInitiateCheckout(cartList, showOrderTotal);

      setAddPaymentInfo(
        cartList,
        discountList,
        showOrderTotal,
        payment,
        pageName
      );
      setAddShippingInfo(
        cartList,
        discountList,
        showOrderTotal,
        shippingModel,
        pageName
      );
      setTimeout(() => {
        makeOrder(parameters);
      }, MakeOrderDelayTime);
    });
    // });
  };

  const makeOrder = (parameters) => {
    const paymentType = parameters.paymentType;

    if (paymentType === PaymentMethodApplePay) {
      callAppFunc('callApplePay', {
        ...parameters,
        totalPrice: showOrderTotal,
      });
      setTimeout(() => {
        unlockMakeOrderButton();
      }, 500);
      return;
    }

    const invoiceData = {
      invoiceType: parameters.invoiceType,
      invoiceVehicle: parameters.invoiceVehicle,
      uniformNumbers: parameters.uniformNumbers,
    };
    const startTime = new Date().getTime();
    api
      .makeOrder(parameters, makeOrderVersion.current === '2' ? true : false)
      .then((res) => {
        const endTime = new Date().getTime();
        const elapsedTime = endTime - startTime;
        if (successOrderCount >= 4) {
          if (elapsedTime > 3000) setOrderOvertime(true);
        }

        makeOrderAfter(res, paymentType, invoiceData);
      })
      .catch((err) => {
        dismissLoading();
        handleOrderFlowFailed();
        unlockMakeOrderButton();
      });
  };

  const checkMobileCode = (code, callback) => {
    showLoading();

    api
      .checkMobileCode({ barcode: code })
      .then((res) => {
        dismissLoading();
        if (!res) {
          callback(false);
          return;
        }

        if (callback !== undefined) callback(true);
      })
      .catch((error) => {
        dismissLoading();
        callback(false);
      });
  };

  const validateTaiwanPhoneNumber = (phoneNumber) => {
    // 使用正則表達式驗證台灣手機號碼格式
    // 台灣手機號碼格式為09開頭，後面接8個數字
    var regex = /^09\d{8}$/;

    return regex.test(phoneNumber);
  };

  const validPaymentCollectionData = (params, callback) => {
    let isValid = true;

    if (!params.receiverName) {
      alert('請新增收件人後再下單', () => {
        if (postData.current.addressId !== '') {
          navigate('/member/receiverV2/edit', {
            state: {
              data: { data: { addressId: postData.current.addressId } },
            },
            replace: false,
          });
        }
      });
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (!validateTaiwanPhoneNumber(params.receiverPhone)) {
      alert('收件人手機號碼格式錯誤', () => {
        if (postData.current.addressId !== '') {
          navigate('/member/receiverV2/edit', {
            state: {
              data: { data: { addressId: postData.current.addressId } },
            },
            replace: false,
          });
        }
      });
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (cartList.length <= 0) {
      alert('購物車無商品。');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (!validateTaiwanPhoneNumber(params.receiverPhone)) {
      alert('收件人手機號碼格式錯誤');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (!returnContract) {
      alert('請勾選同意代為處理發票');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (!agreePolicy) {
      alert('請確認已閱讀並瞭解條款內說明並打勾');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params === undefined ||
      params === null ||
      Object.keys(params).length === 0
    ) {
      alert('缺少資料');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (shippingModel === '1') {
      if (params.receiverElevator === '') {
        alert('請選擇有無電梯');
        isValid = false;
        if (callback !== undefined) {
          callback(isValid);
        }
        return;
      }
      if (
        params.receiverReceive === undefined ||
        params.receiverReceive === '' ||
        !receiveTypeOptions.some((item) => item.value === receiveTypeValue)
      ) {
        alert('請選擇簽收方式');
        isValid = false;
        if (callback !== undefined) {
          callback(isValid);
        }
        return;
      }
    }

    if (
      params.invoiceType === '3' &&
      (params.invoiceVehicle === '' ||
        params.invoiceVehicle === undefined ||
        params.invoiceVehicle === null)
    ) {
      alert('請輸入手機條碼');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params.invoiceType === '4' &&
      (params.invoiceVehicle === '' ||
        params.invoiceVehicle === undefined ||
        params.invoiceVehicle === null)
    ) {
      alert('請輸入自然人憑證');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    } else if (
      params.invoiceType === '4' &&
      params.invoiceVehicle !== '' &&
      !checkNaturalNumber(params.invoiceVehicle)
    ) {
      alert('自然人憑證不正確');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }
    if (
      params.invoiceType === '1' &&
      (params.uniformNumbers === '' ||
        params.uniformNumbers === '00000000' ||
        params.uniformNumbers === undefined ||
        params.uniformNumbers === null)
    ) {
      alert('選擇公司用發票，請填寫統一編號。');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    } else if (
      params.invoiceType === '1' &&
      params.uniformNumbers !== '' &&
      !checkTaxNumberV2(params.uniformNumbers)
    ) {
      alert('統編格式不正確');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (
      params.paymentType === undefined ||
      (params.paymentType === PaymentMethodCreditCard && !params.creditCardNo)
    ) {
      alert('請選擇支付方式');
      isValid = false;
      if (callback !== undefined) {
        callback(isValid);
      }
      return;
    }

    if (params.invoiceType === '3') {
      checkMobileCode(params.invoiceVehicle, (result) => {
        if (!result) {
          alert('手機條碼格式錯誤');
          isValid = false;
          if (callback !== undefined) {
            callback(isValid);
          }
        }
        if (callback !== undefined) {
          callback(isValid);
        }
      });
    } else {
      if (callback !== undefined) {
        callback(isValid);
      }
    }
  };

  const setPaymentFunction = (data) => {
    if (data.payment === undefined) {
      const tmpPaymentString = sessionStorage.getItem('TmpPaymentData');
      if (
        tmpPaymentString !== undefined &&
        tmpPaymentString !== null &&
        tmpPaymentString !== ''
      ) {
        const paymentData = JSON.parse(tmpPaymentString);
        store.dispatch(actions.setPayment(paymentData));
      } else {
        var keepPaymentString = localStorage.getItem('KeepPaymentType');
        if (
          keepPaymentString !== undefined &&
          keepPaymentString !== null &&
          keepPaymentString !== ''
        ) {
          const data = JSON.parse(keepPaymentString);
          if (data.payment !== undefined) {
            store.dispatch(
              actions.setPayment({
                ...payment,
                payment: Number.parseInt(data.payment),
              })
            );
          }
        }
      }
    } else {
      store.dispatch(
        actions.setPayment({
          ...payment,
          ...data,
        })
      );
    }
  };

  const getCartFinalStep = () => {
    setIsNeedCheckQty(false);
    const shippingModel = postData.current.shippingModel;
    api
      .getCartListFinalStep({ shippingModel: postData.current.shippingModel })
      .then((res) => {
        setIsCheckQtyFinished(true);
        //目前訂單折價券與折扣碼只能擇其一使用 因此只會有一筆
        let currentUseCoupon = '';
        if (res.discountList.length > 0) {
          currentUseCoupon = res.discountList[0].couponCode;
        }
        store.dispatch(actions.setCurrentUseCoupon(currentUseCoupon));
        cartListDataHandler(res);
      })
      .catch((error) => {});
  };

  const checkQtyFinish = () => {
    checkQtyCount.current = checkQtyCount.current + 1;
    if (
      checkQtyCount.current ==
      cartList.length + cartAdditionItems.length + cartGiftItems.length
    ) {
      checkQtyCount.current = 0;
      // console.log('check finished');
      // console.log('saleOutList', saleOutList);
      // if (saleOutList.length > 0) {
      //   let count = 0;
      //   var showDeleteItemsMessage = '';
      //   saleOutList.map((item) => {
      //     showDeleteItemsMessage += item + '\n';
      //     count++;
      //   });
      //   saleOutList = [];
      //   setSaleOutContent(showDeleteItemsMessage);
      if (saleOutList.length > 0) {
        setShowSaleOut(true);
      }

      getCartFinalStep();
    }
  };

  const getCart = () => {
    setIsNeedCheckQty(true);
    setIsCheckQtyFinished(false);
    setShowModalCoupon(false);
    api
      .getCartListNoneCheck({ shippingModel: postData.current.shippingModel })
      .then((res) => {
        //一開始如果就沒商品就出去首頁
        if (res.productItems.length <= 0) {
          alert('目前購物車沒有商品。', () => {
            callAppFunc('backMain', {});
          });
          return;
        }

        setInsiderCartPageView(res.productItems);

        setUpdateAddition(true);
        cartListDataHandler(res);
        setShowCartItems(true);
        setShowPage(true);
      })
      .catch((error) => {
        // dismissLoading();
      });
  };

  const cartListDataHandler = (res) => {
    setRecycleBag(res.recyclebag);
    //判斷productItem裡是否有垃圾袋
    const bag = res.productItems.some(
      (item) => item.productId === res.recyclebag.productId
    );
    setCheckBag(bag);
    if (!res) {
      callAppFunc('storeClose', {});
      return;
    }

    //從saleouthandle進來且res沒商品先擋下來
    //否則carlist被res.productItems被蓋掉的話
    //因為要讓缺貨alert點了確認返回首頁那邊做判斷
    if (res.productItems.length <= 0) {
      return;
    }

    const storeId = postData.current.storeId;
    // checkStock(storeId, (isValid) => {});

    //將商品區分為加價購與一般商品
    const arrAdditionItems = res.productItems.filter(
      (element) => element.isAdditionItem
    );
    const arrNormalItems = res.productItems.filter(
      (element) => !element.isAdditionItem && !element.isGiftItem
    );
    const arrGiftItems = res.productItems.filter(
      (element) => element.isGiftItem
    );
    setCartList(arrNormalItems);
    store.dispatch(actions.setCartAdditionItems(arrAdditionItems));
    store.dispatch(actions.setCartGiftItems(arrGiftItems));
    setShowCartAdditionItems(true);
    setShowCartGiftItems(true);
    store.dispatch(actions.setCouponErrorMsg(res?.couponMessage));
    store.dispatch(actions.setCreditInfo(res.shippingMethod.creditCardInfo));
    store.dispatch(actions.setPaymentMethods(res.shippingMethod.paymentMethod));

    setCartData(res);
    setProductTotal(res.productTotal);
    setShippingTotal(res.shippingTotal);
    setDiscount(res.discount);
    setOrderTotal(res.orderTotal);
    setTotalQty(res.totalqtyCount);
    setFreeShippingAmount(
      IN_APP
        ? postData.current.shippingModel === '1'
          ? res.shippingMethod.freeShippingAmount
          : res.shippingMethod.pickOnStoreAmount
        : res.shippingMethod.freeShippingAmount
    );
    setShippingFinal(res.shippingFinal);
    setTbGoogleSwitch(res.tbGoogleSwitch);
    updateOrderDiscountInfo(res.approachingOrderDiscounts);

    const hasCp = res.discountList.find((item) => {
      return item.type === 'coupon';
    });

    setHasCoupon(hasCp !== undefined && hasCoupon !== null);
    if (res.discountList.length > 0) {
      // setDiscountCode(discountCodeInput.value);
      // setHasCoupon(
      //   discountCodeInput.value !== undefined &&
      //     discountCodeInput.value !== ''
      // );
      setDiscountList(res.discountList);
    }

    let isViewCart = sessionStorage.getItem('viewCart');
    if (isViewCart === null) {
      setViewCart(hashAccount, res, pageName);
      // appsflyer view cart
      setAppsFlyerViewCart(res);
      // meta
      setMetaViewCart(res);
      sessionStorage.setItem('viewCart', '1');
    }

    checkBonus(
      res.orderTotal,
      res.productTotal,
      res.discount,
      res.shippingTotal
    );
  };

  const removeCoupons = () => {
    api
      .removeCoupons()
      .then((res) => {
        if (!res) {
          return;
        }
        getCart();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        setReceiveTypeOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendDispatchData') {
      const data = JSON.parse(decode(dataJson.params.data));
      // 增加make order 版本判斷
      makeOrderVersion.current = dataJson.params.makeOrderVersion;

      handleAppData(data);
    }
    if (dataJson.fnName === 'sendProductFeedData') {
      productFeedData.current = dataJson.params.data;
    }
    if (dataJson.fnName === 'sendApplePayData') {
      console.log('dataJson', dataJson);
      const data = dataJson.params.data;
      makeOrderAfter(data, payment.payment);
    }
  };

  const go2Dispatch = () => {
    const params = collectionData();
    localStorage.setItem('TmpCartData', JSON.stringify(params));
    sessionStorage.setItem(
      'TmpPaymentData',
      payment ? JSON.stringify(payment) : ''
    );
    callAppFunc('showDispatchSetting', { tbGoogleSwitch: tbGoogleSwitch });
  };

  const handleAppData = (data = postData.current) => {
    postData.current = data;
    appDispatchData.current = data;
    //儲存暫存
    const tmpJsonString = localStorage.getItem('TmpCartData');
    if (tmpJsonString !== null) {
      const tmpJson = JSON.parse(tmpJsonString);
      // postData.current.receiverAddress = tmpJson.receiverAddress;
      // postData.current.storeName = tmpJson.storeName;
      postData.current.receiverReceive = tmpJson.receiverReceive;
      postData.current.receiverElevator = tmpJson.receiverElevator;
      // postData.current.shippingDate = tmpJson.shippingDate;
      // postData.current.fastDeliveryPeriod = tmpJson.fastDeliveryPeriod;
      // postData.current.shippingPeriod = tmpJson.shippingPeriod;
      // postData.current.shippingTimeslot = tmpJson.shippingTimeslot;
      postData.current.invoiceType = tmpJson.invoiceType;
      postData.current.receiverAddressRemark = tmpJson.receiverAddressRemark;
      postData.current.remark = tmpJson.remark;
      postData.current.invoiceVehicle = tmpJson.invoiceVehicle;
      postData.current.uniformNumbers = tmpJson.uniformNumbers;
      postData.current.companyNo = tmpJson.companyNo;

      if (postData.current.invoiceType === '1') {
        setCompanyNo(postData.current.uniformNumbers);
      } else if (postData.current.invoiceType === '4') {
        setNatural(postData.current.invoiceVehicle);
      } else if (postData.current.invoiceType === '3') {
        setMobileCode(postData.current.invoiceVehicle);
      }

      setInvoiceValue(postData.current.invoiceType ?? '0');
      setNoteOrder(postData.current.remark);
    }
    const invoiceData = localStorage.getItem('KeepInvoice');
    if (invoiceData) {
      const data = JSON.parse(invoiceData);
      const { invoiceType, invoiceVehicle, uniformNumbers } = data;
      if (
        invoiceType !== '0' &&
        invoiceType !== undefined &&
        invoiceType !== null
      ) {
        setInvoiceValue(invoiceType);
        switch (invoiceType) {
          case INVOICE_TYPE.Mobile:
            setMobileCode(invoiceVehicle);
            break;
          case INVOICE_TYPE.CitizenDigital:
            setNatural(invoiceVehicle);
            break;
          default:
            setCompanyNo(uniformNumbers);
            break;
        }
      }
    }
    setNoteDispatch(postData.current.receiverAddressRemark);
    setShippingModel(postData.current.shippingModel);
    setReceiverAddress(postData.current.receiverAddress);
    setStoreName(postData.current.storeName);
    setStoreAddress(postData.current.storeAddress);
    setReceiveTypeValue(postData.current.receiverReceive);
    setElevatorValue(postData.current.receiverElevator);

    store.dispatch(
      actions.setUserInfo({
        ...userInfo,
        mobile: postData.current.mobile,
        // skey: postData.current.skey,
      })
    );
    getCart();
    getTimeSlots();
    getAddtionItems();
  };

  const getAddtionItems = () => {
    //先將資料清空 觸發render
    store.dispatch(actions.setAdditionItems([]));
    const params = {
      shippingModel: 1,
    };
    api
      .getAddtionItems(params)
      .then((res) => {
        store.dispatch(actions.setAdditionItems(res.rows));
      })
      .catch((error) => {});
  };

  const getTimeSlots = () => {
    api
      .getStoreTimeSlots(IN_APP ? postData.current.storeId : '')
      .then((res) => {
        if (!res) {
          return;
        }
        store.dispatch(actions.setStoreTimeSlots(res));
      })
      .catch((error) => {
        console.log('get address exception =', error);
      });
  };

  const saleOutHandle = (itemName) => {
    console.log('saleOutHandle', itemName);
    if (saleOutList.length > 0) {
      let isSame = false;
      saleOutList.forEach((saleOutName) => {
        if (saleOutName.localeCompare(itemName) == 0) {
          isSame = true;
        }
      });
      if (isSame != true) {
        saleOutList.push(itemName);
      }
    } else {
      saleOutList.push(itemName);
    }
  };

  const getWallet = () => {
    if (
      userInfo.skey === undefined ||
      userInfo.skey === null ||
      userInfo.skey === ''
    ) {
      setWalletNotOpen(true);
      setShowWalletText('尚未開通');
      unlockMakeOrderButton();
      return;
    }
    api
      .getCarrefourWallet({
        shop_id: IN_APP ? postData.current.storeId : '',
        account_id: userInfo.skey,
      })
      .then((result) => {
        if (result.code !== '200') {
          if (result.code === '400') {
            // if (result.data.res_code === '1002') {
            //   setWalletNotOpen(true);
            // }
            setWalletNotOpen(true);
            setShowWalletText('無法取得錢包資訊');
          } else {
            alert(result.reason);
          }

          unlockMakeOrderButton();
          return;
        }

        setWalletNotOpen(false);

        setWalletValue(result.data.total_wallet_amt);
      })
      .catch((error) => {
        unlockMakeOrderButton();
        console.log('get address exception =', error);
      });
  };

  const validCode = (code, callback) => {
    const getMobile = () => {
      if (userInfo.mobile) {
        return Promise.resolve(userInfo.mobile);
      } else if (memberProfile && memberProfile.mobile) {
        return Promise.resolve(memberProfile.mobile);
      } else {
        return api.getMemberProfile().then((data) => {
          if (!data || !data.mobile) {
            throw new Error('會員電話錯誤');
          }
          return data.mobile;
        });
      }
    };

    showLoading();

    getMobile()
      .then((mobile) => api.validCode({ mobile, code }))
      .then((res) => {
        dismissLoading();
        if (callback) {
          callback(!!res);
        }
      })
      .catch((error) => {
        dismissLoading();
        console.error(error);
        if (callback) {
          callback(false);
        }
      });
  };

  const sendValidCode = (callback) => {
    const getMobile = () => {
      if (userInfo.mobile) {
        return Promise.resolve(userInfo.mobile);
      } else if (memberProfile.mobile) {
        return Promise.resolve(memberProfile.mobile);
      } else {
        return api.getMemberProfile().then((data) => {
          if (!data) {
            throw new Error('會員資料有誤');
          }
          return data.mobile;
        });
      }
    };

    showLoading();

    getMobile()
      .then((mobile) => {
        if (!mobile) {
          throw new Error('會員資料有誤，無法取得手機號碼');
        }
        return api.sendValidCode({ mobile });
      })
      .then((res) => {
        dismissLoading();
        if (callback) {
          callback(!!res);
        }
      })
      .catch((error) => {
        dismissLoading();
        console.error(error);
        if (callback) {
          callback(false);
        }
      });
  };

  const walletValidCode = (password, callback) => {
    showLoading();
    api
      .walletCodeValid({ pwd: password, skey: userInfo.skey })
      .then((res) => {
        if (!res) {
          if (callback) {
            callback(false);
          }
          return;
        }
        walletPassword.current = password;

        if (callback) {
          callback(true);
        }
      })
      .catch((error) => {
        dismissLoading();
      });
  };

  const checkStock = (storeId = '', callback = null) => {
    api
      .checkStock(storeId)
      .then((res) => {
        if (typeof res === 'object' && res.type === 'closeStore') {
          callAppFunc('storeClose', {});
          if (callback) {
            callback(false);
          }
          return;
        }
        if (!res) {
          if (callback) {
            callback(false);
          }
          return;
        }
        if (res.deleteItem.length > 0) {
          dismissLoading();
          var showDeleteItemsMessage = '門店暫時缺貨將刪除:\n';
          res.deleteItem.map((item) => {
            showDeleteItemsMessage += item.itemName + '\n';
          });
          alert(showDeleteItemsMessage, () => {
            getCart();
          });
          if (callback) {
            callback(false);
          }
          return;
        }
        if (callback) {
          callback(true);
        }
      })
      .catch((error) => {
        dismissLoading();
      });
  };

  const onNeedValidCode = (code) => {
    validCode(code, (finished) => {
      if (finished) {
        if (payment.payment === PaymentMethodC4Wallet) {
          walletValid.current = true;
          checkPaymentMethod();
          return;
        }
        // use point pass
        clpeValid.current = true;
        checkPaymentMethod();
      } else {
        unlockMakeOrderButton();
      }
    });
  };

  const onNeedWalletValidCode = (finished) => {
    if (finished) {
      fetchOrder();
    } else {
      unlockMakeOrderButton();
      unlockMakeOrderButton();
    }
  };

  const maskCardNo = (number) => {
    if (number === undefined || number === null || number === '') {
      return '';
    }
    return `${'*'.repeat(number.length - 4)}${number.slice(-4)}`;
  };

  const checkNaturalNumber = (naturalNumber = '') => {
    if (naturalNumber === '') {
      return true;
    }

    var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;

    return regexp.test(naturalNumber);
  };

  const setTimeSlotsMethod = (data) => {
    setSelectTimeslot(data);
    if (
      typeof appDispatchData.current == 'object' &&
      Object.keys(appDispatchData.current).length == 0
    ) {
      return;
    }
    appDispatchData.current = {
      ...appDispatchData.current,
      shippingDate: data ? data.shippingDate ?? '' : '',
      shippingPeriod: data ? data.shippingPeriod ?? '' : '',
      shippingTimeslot: data ? data.shippingTimeslot ?? '' : '',
      fastDeliveryPeriod: data ? data.fastDeliveryPeriod ?? '' : '',
    };
    // try {
    callAppFunc('saveDispatchData', {
      data: JSON.stringify(appDispatchData.current),
    });
    // } catch (e) {}
  };

  const getMemberProfile = () => {
    api
      .getMemberProfile()
      .then((data) => {
        if (!data) return;
        setMemberProfile((state) => ({ ...state, ...data }));
      })
      .catch(() => {});
  };
  const handleOrderFlowFailed = () => {
    if (successOrderCount >= 4) setOrderFlowFailed(true);
  };

  const updateOrderDiscountInfo = (info) => {
    setOrderDiscountInfo(info);
  };

  useEffect(() => {
    var totalQty = 0;
    if (cartList !== undefined) {
      cartList.forEach((element) => {
        totalQty += element.qty;
      });
      setTotalQty(totalQty);
      setCartListUpdate(true);
    }
  }, [cartList]);

  useEffect(() => {
    return () => {
      setCartListUpdate(false);
    };
  }, [cartListUpdate]);

  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchDispatchData', {});
    callAppFunc('fetchProductFeedData', {});
  }, []);

  useEffect(() => {
    if (
      typeof postData.current === 'object' &&
      Object.keys(postData.current).length > 0
    ) {
      // 表示有預設資料
      var findIndex = 0;
      var findData = false;

      const result = storeTimeSlots.find((slot, index) => {
        if (
          slot.shippingDate === postData.current.shippingDate &&
          slot.fastDeliveryPeriod === postData.current.fastDeliveryPeriod &&
          slot.shippingPeriod === postData.current.shippingPeriod &&
          slot.shippingTimeslot === postData.current.shippingTimeslot
        ) {
          findIndex = index;
          return true;
        }
      });
      if (result !== undefined && result != null) {
        setSelectTimeslot(result);
        setSelectTimeslotIndex(findIndex);
        findData = true;
      } else {
        if (
          postData.current.shippingPeriod === '-1' &&
          postData.current.fastDeliveryPeriod === ''
        ) {
          const resultEnd = storeTimeSlots.find((slot, index) => {
            if (slot.shippingPeriod === '-1') {
              findIndex = index;
              return true;
            }
          });

          if (resultEnd !== undefined && resultEnd !== null) {
            setSelectTimeslot(resultEnd);
            setSelectTimeslotIndex(findIndex);
            findData = true;
          }
        }
      }

      if (!findData && storeTimeSlots.length > 0) {
        setSelectTimeslot(storeTimeSlots[0]);
        setSelectTimeslotIndex(0);
        findData = true;
      }
    }
  }, [storeTimeSlots]);

  useEffect(() => {
    setSelectTimeslotIndex();
    getTimeSlots();
  }, [setSelectTimeslot]);

  useEffect(() => {
    initTPDirect();
  }, []);

  useEffect(() => {
    getOptions();
    getMemberProfile();
  }, []);

  useEffect(() => {
    if (tbGoogleSwitch === 'TB') {
      api
        .addrStandardization(postData.current.receiverAddress)
        .then((res) => {
          setAdjustedAddress(res[0].adjusted_address);
          setFloor(res[0].floor);
        })
        .catch((error) => {});
    }
  }, [tbGoogleSwitch]);

  useEffect(() => {
    setTimeout(() => {
      if (updateAddition) {
        setUpdateAddition(false);
      }
    }, 100);
  }, [updateAddition]);

  useEffect(() => {
    setTimeout(() => {
      if (updateRecommendBlock) {
        setUpdateRecommendBlock(false);
      }
    }, 100);
  }, [updateRecommendBlock]);

  return (
    <>
      <div className="cart">
        <div className="header">
          <HeaderBar
            title={'購物車'}
            subtitle={storeName}
            subtitleCentered={true}
            goBack={() => {
              const params = collectionData();
              localStorage.setItem('TmpCartData', JSON.stringify(params));
              sessionStorage.setItem(
                'TmpPaymentData',
                payment ? JSON.stringify(payment) : ''
              );
              sessionStorage.removeItem('viewCart');
              callAppFunc('backHome', {});
            }}
          />
        </div>
        <div>
          <DeliveryBar
            defaultSelectIndex={selectTimeslotIndex}
            timeslots={storeTimeSlots}
            setSelectTimeSlots={(data) => {
              setTimeSlotsMethod(data);
            }}
            shippingModel={IN_APP ? postData.current.shippingModel : '1'}
          />
          <AddressBar onClick={go2Dispatch}>
            <div className="address-block">
              <div>
                <img src={imageLocationPoint} />
                <span>
                  {shippingModel === '1'
                    ? adjustedAddress === ''
                      ? receiverAddress
                      : adjustedAddress
                    : storeAddress}
                </span>
              </div>
              <img src={imageAngleArrorRight} />
            </div>
          </AddressBar>
          {shippingModel === '1' && (
            <DeliveryNotice>
              {tbGoogleSwitch === 'TB' && (
                <div className="delivery-remark">
                  <div>樓層或房號</div>
                  <textarea
                    style={{
                      minHeight: '10px',
                      height: '39px',
                      lineHeight: '30px',
                    }}
                    maxLength="20"
                    placeholder="樓層/房號"
                    value={floor}
                    onChange={(e) => {
                      setFloor(e.target.value);
                    }}
                  ></textarea>
                </div>
              )}
              <div className="delivery-remark">
                <div>地址備註</div>
                <textarea
                  maxLength="50"
                  className="content"
                  placeholder={
                    tbGoogleSwitch === 'TB'
                      ? '公司大樓名稱、其他地址備註'
                      : '樓層/房號、公司大樓名稱、其他地址備註'
                  }
                  value={noteDispatch}
                  onChange={(e) => {
                    setNoteDispatch(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.target.blur();
                    }
                  }}
                  enterKeyHint="done"
                ></textarea>
              </div>

              <div className="elevator">
                <div className="title">電梯</div>
                <div className="radio1">
                  <input
                    type="radio"
                    id="have-elevator"
                    name="elevator"
                    value="1"
                    checked={'1' === elevatorValue}
                    onChange={(e) => {
                      setElevatorValue(e.target.value);
                    }}
                  />
                  <label htmlFor="have-elevator">
                    <span>
                      <span></span>
                    </span>
                    <div>有</div>
                  </label>
                </div>
                <div className="radio2">
                  <input
                    type="radio"
                    id="no-elevator"
                    name="elevator"
                    value="0"
                    checked={'0' === elevatorValue}
                    onChange={(e) => {
                      setElevatorValue(e.target.value);
                    }}
                  />
                  <label htmlFor="no-elevator">
                    <span>
                      <span></span>
                    </span>
                    <div>無</div>
                  </label>
                </div>
              </div>

              <div>
                <div className="sign">
                  <div className="title">簽收</div>
                  {receiveTypeOptions &&
                    receiveTypeOptions.map((item, index) => (
                      <div key={`div-receive-${index}`} className="radio1">
                        <input
                          type="radio"
                          id={`receive-${index}`}
                          name="sign"
                          value={item.value}
                          checked={item.value === receiveTypeValue}
                          onChange={(e) => {
                            setReceiveTypeValue(e.target.value);
                          }}
                        />
                        <label htmlFor={`receive-${index}`}>
                          <span>
                            <span></span>
                          </span>
                          <div>{item.name}</div>
                        </label>
                      </div>
                    ))}
                </div>
                <div className="notice">
                  如果您的樓層有安全管制，請通知管理室協助簽收。
                </div>
              </div>
            </DeliveryNotice>
          )}
          <CartContainer>
            {orderDiscountInfo.map((info) => {
              return (
                <OrderDiscountsBlock>
                  {info.approachPromotionName} 尚差
                  <span>{info.approachAccount}</span>
                </OrderDiscountsBlock>
              );
            })}
            {cartListUpdate ? undefined : (
              <div className="cart-item-list">
                {cartList?.map((item, index) => {
                  return (
                    item.productId !== recycleBag?.productId &&
                    Object.keys(recycleBag).length != 0 && (
                      <CartItem
                        type={'normal'}
                        key={`cartitem-${item.productId}-${index}`}
                        itemData={item}
                        recycleBag={recycleBag}
                        checkBag={checkBag}
                        remove={deleteItem}
                        update={updateItem}
                        setCheckBag={setCheckBag}
                        checkQtyFinish={checkQtyFinish}
                        isNeedCheckQty={isNeedCheckQty}
                        saleOutHandle={saleOutHandle}
                      />
                    )
                  );
                })}

                {cartList.length != 0 && showCartItems && (
                  <RecycleBagItem
                    key={`${recycleBag.productId}-recycleBag`}
                    itemData={cartList.filter(
                      (item) => item.productId === recycleBag.productId
                    )}
                    itemArrData={cartList}
                    recycleBag={recycleBag}
                    checkBag={checkBag}
                    remove={deleteItem}
                    update={updateItem}
                    setCheckBag={setCheckBag}
                    checkQtyFinish={checkQtyFinish}
                    isNeedCheckQty={isNeedCheckQty}
                    saleOutHandle={saleOutHandle}
                    recycleBagMessage={cartData.recycleBagMessage ?? ''}
                  />
                )}
                {cartAdditionItems.length > 0 && showCartAdditionItems && (
                  <MobileItemListTitle>滿額加價購</MobileItemListTitle>
                )}
                {cartAdditionItems.length > 0 &&
                  showCartAdditionItems &&
                  cartAdditionItems.map((item, index) => {
                    return item.productId !== recycleBag?.productId &&
                      Object.keys(recycleBag).length != 0 ? (
                      <CartItem
                        type={'addition'}
                        key={`cartitem-${item.productId}-${index}`}
                        itemData={item}
                        recycleBag={recycleBag}
                        checkBag={checkBag}
                        remove={deleteItem}
                        update={updateItem}
                        setCheckBag={setCheckBag}
                        checkQtyFinish={checkQtyFinish}
                        isNeedCheckQty={isNeedCheckQty}
                        saleOutHandle={saleOutHandle}
                      />
                    ) : undefined;
                  })}

                {cartAdditionItems.length > 0 && showCartAdditionItems && (
                  <RecommendBlock>{`每品限加購1件，且不計入現折/折價券/折扣碼計算門檻;未顯示之品項為出貨店已售完`}</RecommendBlock>
                )}
                {cartGiftItems.length > 0 && showCartGiftItems && (
                  <MobileItemListTitle>滿額贈/滿件贈</MobileItemListTitle>
                )}
                {cartGiftItems.length > 0 &&
                  showCartGiftItems &&
                  cartGiftItems.map((item, index) => {
                    return item.productId !== recycleBag?.productId &&
                      Object.keys(recycleBag).length != 0 ? (
                      <CartItem
                        type={'gift'}
                        key={`cartitem-${item.productId}-gift-${index}`}
                        itemData={item}
                        recycleBag={recycleBag}
                        checkBag={checkBag}
                        remove={deleteItem}
                        update={updateItem}
                        setCheckBag={setCheckBag}
                        checkQtyFinish={checkQtyFinish}
                        isNeedCheckQty={isNeedCheckQty}
                        saleOutHandle={saleOutHandle}
                      />
                    ) : undefined;
                  })}
              </div>
            )}
            {freeShippingAmount !== 0 ? (
              <div className="coupon-notification">
                尚差 ${freeShippingAmount} 以達
                {shippingModel === '1' ? '免運' : '店取門檻'}
              </div>
            ) : undefined}

            <div className="total-amount">
              <div>
                <span>小計</span>
                <span>{`$${productTotal}`}</span>
              </div>
              {IN_APP && postData.current.shippingModel === '1' ? (
                <div>
                  <span>外送服務費</span>
                  <span>{`$${shippingTotal}`}</span>
                </div>
              ) : !IN_APP ? (
                <div>
                  <span>外送服務費</span>
                  <span>{`$${shippingTotal}`}</span>
                </div>
              ) : undefined}
              {discount !== 0 ? (
                <div>
                  <span className="discount-amount">
                    折扣&nbsp;
                    {discountList.length > 0 ? (
                      <img
                        className="icon-notice"
                        src={IconNotice}
                        alt="notice"
                        width={18}
                        height={18}
                        onClick={() => {
                          setIsDiscountCardShow(true);
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                  <span className="discount">{`-$${discount}`}</span>
                </div>
              ) : undefined}
              {payment?.clpe?.value && (
                <div>
                  <span className="discount-amount">紅利折抵</span>
                  <span className="discount">-${payment.clpe.value}</span>
                </div>
              )}
              <div>
                <span>總計</span>
                <span>{`$${showOrderTotal}`}</span>
              </div>
            </div>

            <div className={`coupon ${hasCoupon ? 'has-coupon' : ''}`}>
              <div
                className="use-coupon"
                onClick={() => {
                  handleClickCoupon();
                }}
              >
                <span>使用折扣碼/折價券</span>
                <img src={imageAngleArrorRight} />
              </div>
              {hasCoupon && (
                <>
                  <div className="coupon-info">
                    {/* <div
                      className="delete-coupon"
                      onClick={() => removeCoupons()}
                    >
                      刪除
                    </div> */}

                    {discountList
                      .filter((discount) => discount.type === 'coupon')
                      .map((i) => {
                        return (
                          <>
                            <p className="coupon-desc">{i.title}</p>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </div>

            <div
              className="payment"
              onClick={() => {
                pageCheck(api, () => {
                  const params = collectionData();
                  localStorage.setItem('TmpCartData', JSON.stringify(params));
                  sessionStorage.setItem(
                    'TmpPaymentData',
                    payment ? JSON.stringify(payment) : ''
                  );
                  navigate('/cart/payment-option', {
                    replace: false,
                    state: {
                      orderTotal,
                      productTotal: productTotal,
                      discount:
                        discount - shippingTotal > 0
                          ? discount - shippingTotal
                          : 0,
                      bonusThreshold: postData.current.bonusThreshold,
                      clpeRatio: postData.current.clpeRatio,
                      payment: payment,
                      hkDiscountPrice: cartData.hkDiscountPrice,
                    },
                  });
                });
              }}
            >
              <div className="title">付款方式</div>
              <div className="credit-block">
                {payment?.payment === PaymentMethodCreditCard ? (
                  <div className="credit-card">
                    <img src={imageMastercard} style={{ width: '20%' }} />
                    <span>
                      {payment.cardInfo
                        ? maskCardNo(payment.cardInfo.cardNo)
                        : creditInfo
                        ? maskCardNo(creditInfo.creditCardNumber)
                        : ''}
                    </span>
                  </div>
                ) : payment?.payment === PaymentMethodC4Wallet ? (
                  <div className="credit-card">
                    <img src={IconWallet} />
                  </div>
                ) : payment?.payment === PaymentMethodApplePay ? (
                  <div className="credit-card">
                    <img src={IconApplePay} width={50} />
                  </div>
                ) : payment?.payment === PaymentMethodLinePay ? (
                  <div className="credit-card">
                    <img src={IconLinepay} />
                  </div>
                ) : payment?.payment === PaymentMethodJkoPay ? (
                  <div className="credit-card">
                    <img src={IconJko} width={70} />
                  </div>
                ) : payment?.payment === PaymentMethodC4Point ? (
                  <div className="credit-card">紅利全額折抵</div>
                ) : (
                  <div className="credit-card">尚未選擇</div>
                )}
                <img src={imageAngleArrorRight} />
              </div>
            </div>

            {/** 推薦商品區塊 */}
            {!updateRecommendBlock && (
              <RecommendProductBlock
                remove={getCartFinalStep}
                update={getCartFinalStep}
              />
            )}

            {/** 加價購商品區塊 */}
            {!updateAddition && (
              <AdditionBlock
                remove={getCartFinalStep}
                update={getCartFinalStep}
              />
            )}

            {!isInvoiceTypeDisabled && (
              <div className="invoice">
                <div className="title">發票</div>
                <div className="carrier-block">
                  <input
                    type="radio"
                    id="carrier"
                    name="invoice"
                    value="0"
                    checked={'0' === invoiceValue}
                    onChange={(e) => {
                      setInvoiceValue(e.target.value);
                    }}
                  />
                  <label htmlFor="carrier">
                    <span>
                      <span></span>
                    </span>
                    <div>會員載具</div>
                  </label>
                </div>
                <div className="carrier-block">
                  <input
                    type="radio"
                    id="barcode"
                    name="invoice"
                    value="3"
                    checked={'3' === invoiceValue}
                    onChange={(e) => {
                      setInvoiceValue(e.target.value);
                    }}
                  />
                  <label htmlFor="barcode">
                    <span>
                      <span></span>
                    </span>
                    <div>手機條碼</div>
                  </label>
                  {'3' === invoiceValue ? (
                    <input
                      className="certificate"
                      type="text"
                      placeholder="輸入手機條碼"
                      value={mobileCode}
                      onChange={(e) => {
                        let str = e.target.value.toUpperCase();
                        setMobileCode(str);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur();
                        }
                      }}
                      enterKeyHint="done"
                    />
                  ) : undefined}
                </div>
                <div className="carrier-block">
                  <input
                    type="radio"
                    id="certificate"
                    name="invoice"
                    value="4"
                    checked={'4' === invoiceValue}
                    onChange={(e) => {
                      setInvoiceValue(e.target.value);
                    }}
                  />
                  <label htmlFor="certificate">
                    <span>
                      <span></span>
                    </span>
                    <div>自然人憑證</div>
                  </label>
                  {'4' === invoiceValue ? (
                    <div className="inputdata">
                      <input
                        name="certificate"
                        className="certificate"
                        type="text"
                        placeholder="新增自然人憑證"
                        value={natural}
                        maxLength="16"
                        onChange={(e) => {
                          setNatural(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.target.blur();
                          }
                        }}
                        enterKeyHint="done"
                      />
                      {checkNaturalNumber(natural) ? undefined : (
                        <label className="notice" htmlFor="certificate">
                          自然人憑證不正確
                        </label>
                      )}
                    </div>
                  ) : undefined}
                </div>
                <div className="carrier-block">
                  <input
                    type="radio"
                    id="company"
                    name="invoice"
                    value="1"
                    checked={'1' === invoiceValue}
                    onChange={(e) => {
                      setInvoiceValue(e.target.value);
                    }}
                  />
                  <label htmlFor="company">
                    <span>
                      <span></span>
                    </span>
                    <div>公司用發票(隨貨出)</div>
                  </label>
                  {'1' === invoiceValue ? (
                    <div className="inputdata">
                      <input
                        name="company"
                        className="company"
                        type="text"
                        placeholder="輸入統一編號"
                        value={companyNo}
                        maxLength="8"
                        onChange={(e) => {
                          var str = e.target.value;
                          var result = parseInt(str).toString();
                          if (result !== 'NaN' || str === '') {
                            setCompanyNo(e.target.value);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.target.blur();
                          }
                        }}
                        enterKeyHint="done"
                      />
                      {checkTaxNumberV2(companyNo) ? undefined : (
                        <label className="notice" htmlFor="company">
                          統編格式不正確
                        </label>
                      )}
                    </div>
                  ) : undefined}
                </div>
                <div className="content">
                  <label className="checkbox baseline-top">
                    <input
                      type="checkbox"
                      checked={returnContract}
                      onChange={() => setReturnContract(!returnContract)}
                    />
                    <span>
                      若本人後續辦理退貨作業,同意由家樂福線上購物代為處理電子發票及銷貨退回證明單,以加速退貨退款作業
                    </span>
                  </label>
                </div>
              </div>
            )}

            <div className="provision">
              <div className="title">購物條款</div>
              <div className="content">
                <label className="checkbox baseline-top">
                  <input
                    type="checkbox"
                    name="1"
                    // value={agreePolicy}
                    checked={agreePolicy}
                    onChange={() => {
                      if (agreePolicy) {
                        setAgreePolicy(false);
                      } else {
                        setAgreePolicy(true);
                      }
                    }}
                    // onClick={() => setAgreePolicy(!agreePolicy)}
                  />
                  <span>
                    我已仔細閱讀瞭解家樂福家速配於會員功能項目內載明的隱私權政策、會員服務條碼等所載內容及其意義，並同意遵守現今與未來規範的各種規則。
                  </span>
                </label>
              </div>
            </div>
          </CartContainer>
          {isCheckQtyFinished ? (
            <BottomButton onClick={goMakeOrder}>
              <div className="button-block">
                <div className="confirm-block">
                  <span>確認下單</span>
                  <div>
                    <span>{totalQty}</span>
                  </div>
                </div>
                <div>{`$${showOrderTotal}`}</div>
              </div>
            </BottomButton>
          ) : undefined}
          <div className={`coupon-input-box ${isOpen ? 'open' : ''}`}>
            <div className="cross">
              <img
                onClick={() => {
                  setIsOpen();
                }}
                src={Cross}
                alt="cross"
              />
              <div>輸入優惠碼</div>
              <input className="barcode" {...discountCodeInput} />
            </div>
            <div className="button-block">
              <div onClick={() => handleClickCoupon()} className="button">
                確認使用
              </div>
            </div>
          </div>

          <EmailCombineDialog
            show={showEmail}
            onClose={() => {
              setShowEmail(false);
            }}
            onUpdateFinished={() => {
              setShowModalCoupon(true);
              setShowEmail(false);
            }}
          ></EmailCombineDialog>

          <WalletDialogV2
            key={`wallet-${showWalletDialog}-${walletNotOpen}`}
            show={showWalletDialog}
            onClose={() => {
              setShowWalletDialog(false);
              unlockMakeOrderButton();
            }}
            onClick={onNeedWalletValidCode}
            price={showOrderTotal}
            remain={walletValue}
            showNotOpen={walletNotOpen}
            showText={showWalletText}
            walletPassword={walletPassword}
          />
          <MemberValidationDialog
            key={`membervalid-${showValidationDialog}`}
            show={showValidationDialog}
            onClose={() => {
              setShowValidationDialog(false);
              unlockMakeOrderButton();
            }}
            onClick={onNeedValidCode}
            onClickResend={() => sendValidCode()}
          />
          <DiscountCard
            discountList={discountList}
            isShow={isDiscountCardShow}
            setIsShow={setIsDiscountCardShow}
          />
          <SaleOutAlert
            key="SaleOutAlert"
            show={showSaleOut}
            title="以下商品暫時無法購買，將為您將該商品從購物車當中刪除:"
            saleOutList={saleOutList}
            // content={saleOutContent}
            txtRight="確定"
            onRight={() => {
              setShowSaleOut(false);
              // -1是因為當前的cartlist有包含了垃圾袋 但垃圾袋不算
              if (cartList.length === saleOutList.length) {
                saleOutList = [];
                callAppFunc('backMain', {});
                return;
              }
              saleOutList = [];
            }}
          />
        </div>
      </div>

      <ModalCoupon
        setShowEmail={setShowEmail}
        isOpen={showModalCoupon}
        onCancel={closeModalCoupon}
        getCartFinalStep={getCartFinalStep}
        removeCoupons={removeCoupons}
        discountList={discountList}
      />

      <style jsx>
        {`
          .cart {
            padding: 65px 0 100px 0;
            position: relative;

            .header {
              width: 100vw;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 999;
            }
            .coupon-input-box {
              width: 100%;
              position: fixed;
              left: 0;
              bottom: -100%;
              box-sizing: border-box;
              padding-top: 18px;
              background-color: #ffffff;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
              box-shadow: 0px 10px 15px 10px #e0e0e0;
              transition: bottom 0.5s ease;
              &.open {
                bottom: 0;
              }
              .cross {
                position: relative;
                text-align: center;
                margin-bottom: 32px;
                img {
                  position: absolute;
                  left: 18px;
                  top: 2px;
                }
                div {
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 23px;
                  color: #000000;
                }
                .barcode {
                  width: 80%;
                  height: 40px;
                  background-color: #fffefc;
                  border: 1px solid #e2e2e2;
                  border-radius: 4px;
                  outline: none;
                  margin: 25px 0 20px 17px;
                }
              }
              .qty-disable {
                pointer-events: none;
                opacity: 0.4;
              }
              .button-block {
                height: 60px;
                padding: 20px 16px;
                background-color: #ffffff;
                box-shadow: 0px 10px 15px 10px #e0e0e0;
                display: flex;
                justify-content: center;
                align-items: center;
                .button {
                  width: 100%;
                  height: 60px;
                  padding: 0 35px;
                  background: #5fd2da;
                  border: 1px solid #5fd2da;
                  box-sizing: border-box;
                  border-radius: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 20px;
                  line-height: 29px;
                  color: #ffffff;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default Cart;
